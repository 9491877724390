<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      button-label="Export CSV"
      :onButtonClick="onExportCSV"
      :btnDisabled="!get(dateRange, '0') || !get(dateRange, '1') || !get(payments, 'length')"
      button-variant="secondary"
      :btnPopover="'select start and end date'"
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Payments'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
                <b-btn variant="light" class="ml-2" @click.prevent="clearFilters" v-if="areFiltersApplied" size="sm">
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div>
            </div>

            <div class="row mt-2" v-if="showFilters">
              <div class="col-sm-6 mb-2">
                  <date-picker
                    v-model="dateRange"
                    format="MMMM DD, YYYY"
                    valueType="YYYY-MM-DD"
                    style="width: 100%"
                    id="date"
                    lang="en"
                    placeholder="Filter transaction dates"
                    range
                    @input="filterPayments"
                  ></date-picker>
                </div>

              <div class="col-sm-6 mb-2">
                <v-select
                  label="text"
                  class="form-control v-select-custom"
                  :value="status"
                  v-model="status"
                  :reduce="status => status.value"
                  placeholder="Filter by status"
                  :options="allStatuses"
                  @input="filterPayments"
                >
                </v-select>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="payments"
            :busy="isLoading"
            head-variant="light"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(status)="data">
              <b-badge
                :class="
                  `border border-${TRANSACTION_STATUSES_COLORS[data.item.status]} text-${
                    TRANSACTION_STATUSES_COLORS[data.item.status]
                  }`
                "
                variant="none"
                >{{ $t(`transactionStatuses.${data.item.status}`) }}
              </b-badge>
            </template>

            <template #cell(type)="data"> {{ $t(`transactionTypes.${data.item.type}`) }} </template>

            <template #cell(amount)="data"> {{ formatAmount(data.item.amount) }} </template>

            <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="payments-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import AmountFormatter from '@/mixins/amount-formatter';
import { DEFAULT_PAGE_SIZE, TRANSACTION_STATUSES_COLORS } from '@/common/constants';
import { formatFullDate } from '@/common/utils';
import DatePicker from 'vue2-datepicker';

export default {
  components: { MdIcon, PageHeader, Pagination, DatePicker, vSelect },
  extends: Page,
  mixins: [AmountFormatter],

  name: 'PaymentHistory',

  data() {
    return {
      title: 'Payment History',
      TRANSACTION_STATUSES_COLORS,

      isLoading: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalPayments: 0,
      dateRange: [],
      payments: [],
      searchTerm: '',
      showFilters: true,
      status: null,
      allStatuses: [
        { value: 'pending', text: 'Pending' },
        { value: 'succeeded', text: 'Success' },
        { value: 'failed', text: 'Failed' },
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Payments', to: { name: 'manage-payment' } },
        { text: 'Payment History', active: true },
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    areFiltersApplied() {
      return !!get(this.dateRange, '0') || this.status;
    },
    tableColumns() {
      return [
        { key: 'id', label: 'Ref. ID' },
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },

  methods: {
    ...mapActions('payoutAcc', ['getPayments', 'exportPaymentPdf']),
    ...mapActions('fileDownload', ['downloadFile']),
    formatFullDate,
    get,

    async fetchPayments(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;
      response = await this.getPayments({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.status && { status: this.status }),
        ...(!!this.dateRange.length && { start_date: this.dateRange[0] }),
        ...(!!this.dateRange.length && { end_date: this.dateRange[1] }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.payments = response.data.results;
      this.currentPage = pageNum;
      this.totalPayments = response.data.count;
      this.isLoading = false;
    },
    filterPayments() {
      this.payments = [];
      this.fetchPayments();
    },
    async onExportCSV() {
      const startDate = new Date(this.dateRange[0]);
      const endDate = new Date(this.dateRange[1]);
      const difference = endDate - startDate;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      if (days > 365) {
        this.makeToast({ variant: 'danger', msg: 'Please select maximum date range of 1 year.' });
        return;
      }
      const h = this.$createElement;
      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });
      try {
        const response = await this.exportPaymentPdf({
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
          ...(this.status && { status: this.status }),
        });
        this.downloadFile({ fileUrl: response.data.url, removeTimestamp: true });
        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: 'No payment history found in this date range.' });
      }
    },
    onPageChange(pageNum) {
      this.fetchPayments(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPayments();
    },
    clearFilters() {
      this.searchTerm = '';
      this.status = null;
      this.dateRange = [];
      this.fetchPayments();
    },

    onSearch() {
      this.debouncedSearchPayments(this);
    },

    debouncedSearchPayments: debounce(vm => {
      vm.fetchPayments();
    }, 500),
  },

  async mounted() {
    this.fetchPayments();
  },
};
</script>
