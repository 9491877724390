import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllStudents(_obj, params) {
    const res = await axios.get(`api/students/`, { params });
    return res.data;
  },

  async getStudent(_obj, id) {
    const res = await axios.get(`api/students/${id}/`);
    return res.data;
  },

  async createStudent(_obj, data) {
    return axios.post(`api/students/`, data);
  },

  async updateStudent(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/`, data);
  },

  async deleteStudent(_obj, id) {
    return axios.delete(`api/students/${id}/`);
  },

  async getStudentUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/students/upload-url/`, data)).data.data;
  },

  async getStudentRegProfile(_obj, params) {
    const res = await axios.get(`api/students/registration-profile/`, { params });
    return res.data;
  },

  async updateStdRegPayStatus(_obj, data) {
    return axios.patch(`api/students/update-reg-pay-status/`, data);
  },
  async createRegPaymentIntent(_obj, data) {
    const res = await axios.post(`api/students/create-payment-intent/`, data);
    return res.data.data;
  },
  async createInitiateRegPaymentIntent(_obj, data) {
    const res = await axios.post(`api/students/create-initiate-payment-intent/`, data);
    return res.data.data;
  },
  async registrationPaymentSuccess(_obj, params) {
    return axios.get(`api/students/register-payment-success/`, { params });
  },
  async registrationPaymentFailed(_obj, params) {
    return axios.delete(`api/students/unregister-payment-failure/`, { params });
  },

  async getSchoolStudentAggregate(_obj, params) {
    const res = await axios.get(`api/students/school-aggregate/`, { params });
    return res.data;
  },
  async getInitiateReg(_obj, params) {
    const res = await axios.get(`api/students/initiate-registration/initiate-registration-draft/`, { params });
    return res.data;
  },
  async getSchoolRegAggregate(_obj, params) {
    const res = await axios.get(`api/students/registration-aggregate/`, { params });
    return res.data;
  },
  async getRegisteredClasses(_obj, params) {
    const res = await axios.get(`api/students/student_registered_classes/`, { params });
    return res.data;
  },
  async initiateStudentRegistration(_obj, data) {
    return axios.post(`api/students/initiate-registration/`, data);
  },
  async allInitiateStudents(_obj, params) {
    const res = await axios.get(`api/students/initiate-registration/`, { params });
    return res.data;
  },
  async registerStudentManually(_obj, data) {
    return axios.post(`api/students/register-student-manually/`, data);
  },
  async resendAllInitiates(_obj, params) {
    return axios.get(`api/students/initiate-registration/resend-reg-email/`, { params });
  },
  async deleteInitiate(_obj, id) {
    return axios.delete(`api/students/initiate-registration/${id}/`);
  },
  async createManuallyPayment(_obj, data) {
    const res = await axios.post(`api/students/create-payment-manually/`, data);
    return res.data.data;
  },

  async updateStdRegStatus(_obj, data) {
    return axios.patch(`api/students/change-registration-status/`, data);
  },

  async updateStdPlanToPay(_obj, data) {
    return axios.patch(`api/students/update-plan-to-pay/`, data);
  },

  async updateStdComment(_obj, data) {
    return axios.patch(`api/students/comment-registration/`, data);
  },

  async getStudentPaymentPlans(_obj, params) {
    const res = await axios.get(`api/students/registration-payment-plans/`, { params });
    return res.data;
  },

  async updateStdPaymentPlanStatus(_obj, data) {
    return axios.patch(`api/students/update-registration-payment-plans/`, data);
  },

  async createAnalysisReport(_obj, data) {
    const res = await axios.post(`api/students/reg-analysis-report/`, data);
    return res.data.data;
  },

  async getStudentCancelation(_obj, params) {
    const res = await axios.get(`api/students/fetch-agreement-form/`, { params });
    return res.data;
  },

  async getStudentEnrollStatus(_obj, params) {
    const res = await axios.get(`api/students/tr-get-enroll-status/`, { params });
    return res.data;
  }
};

export const student = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
