<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex align-items-center"
            v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
          >
            <p class="backResult text-50">
              <a @click.prevent="goBack" class="clickable-item" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>
              </a>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">person</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">email</i><b-skeleton width="75%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">phone</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                      <h6 class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i><b-skeleton width="70%"></b-skeleton>
                      </h6>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div
          class="container page__container d-flex align-items-center"
          v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
        >
          <p class="backResult text-50">
            <a @click.prevent="goBack" style="text-decoration: underline" class="clickable-item">
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </a>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <user-avatar
            size="7.3rem"
            :user="student.user"
            rounded
            hide-text
            class="mr-md-32pt mb-32pt mb-md-0 mobilemb10"
          />

          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4>{{ student.user.first_name }} {{ student.user.last_name }}</h4>

            <b-dropdown text="Contact" variant="primary" class="ml-2">
              <b-dropdown-item :href="`mailto:${student.user.email}`"
                ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
              >
              <b-dropdown-item :href="`tel:${student.user.phone}`"
                ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-1">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">Basic Information</h5>
                </div>

                <div class="d-flex">
                  <div class="flex">
                    <h6 class="icon-align">
                      <i class="material-icons icon-18pt mr-8pt">email</i>{{ student.user.email }}
                    </h6>
                    <h6 class="icon-align">
                      <i class="material-icons icon-18pt mr-8pt">phone</i>{{ student.user.phone }}
                    </h6>
                    <h6 class="icon-align" v-if="get(student, 'city') || get(student, 'state')">
                      <i class="material-icons icon-18pt mr-8pt"> location_on </i>Lives near {{ student.city }},
                      {{ student.state }}
                    </h6>
                    <h6 class="icon-align" v-if="get(student, 'user.gender')">
                      <i class="material-icons icon-18pt mr-8pt">person</i>Gender:
                      {{ $t(`genderOptions.${student.user.gender}`) }}
                    </h6>
                    <h6 class="icon-align" v-if="get(student, 'birthday')">
                      <i class="material-icons icon-18pt mr-8pt">event</i>Birth Date: {{ formatBirthDate }}
                    </h6>
                    <h6 class="icon-align" v-if="student.occupation">
                      <i class="material-icons icon-18pt mr-8pt">work</i>Working as {{ student.occupation }}
                    </h6>

                    <h6 class="icon-align" v-if="student.student_ssn">
                      <span>
                        <i class="material-icons icon-18pt mr-8pt">confirmation_number</i>Social Security Number (SSN):
                        ***-**-{{ student.student_ssn }}
                        <!-- <div style="margin-left: 2rem !important">
                          <i>
                            <a class="text-primary" href="mailto:contact@jointransition.com">
                              Email us at contact@jointransition.com for complete SSN.
                            </a>
                          </i>
                        </div> -->
                      </span>
                    </h6>

                    <h6 class="icon-align" v-if="get(student, 'lang_preferences')">
                      <i v-if="!student.lang_preferences.length">Languages: None</i>

                      <ul v-else>
                        <li v-for="(lang, index) in student.lang_preferences" :key="index">
                          <i class="material-icons icon-18pt">language</i>
                          <span style="font-size: 16px; margin-left: 4px;">
                            Languages:
                            {{ lang }}
                          </span>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
          v-if="
            get(student, 'work_travel') ||
              get(student, 'desired_work_type') ||
              get(student, 'desired_work_time') ||
              get(student, 'desired_work_day') ||
              get(student, 'ged_or_diploma')
          "
        >
          <div class="card-body">
            <div class="d-flex">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <b-link v-b-toggle="'career-section'" class="accordion__toggle p-0">
                    <h5 class="card-title fw600">Career Information</h5>
                    <md-icon class="accordion__toggle-icon mb-3">
                      {{ careerInfoVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                    </md-icon>
                  </b-link>
                </div>

                <b-collapse
                  id="career-section"
                  accordion="accordionId"
                  :visible="careerInfoVisible"
                  class="accordion__menu"
                  @hide="careerInfoVisible = false"
                  @show="careerInfoVisible = true"
                >
                  <div class="d-flex">
                    <div class="flex">
                      <h6 class="icon-align" v-if="student.ged_or_diploma !== undefined">
                        <i class="material-icons icon-18pt mr-8pt">school</i>High School Diploma / GED:
                        {{ student.ged_or_diploma ? 'Yes' : 'No' }}
                        <span class="ml-1" v-if="student.diploma_date">
                          - Graduation: {{ student.diploma_date.split('-')[0] }}
                        </span>
                      </h6>
                      <h6 class="icon-align" v-if="get(student, 'work_travel')">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i>Willing to travel
                        {{ student.work_travel }} miles
                      </h6>
                      <h6 class="icon-align" v-if="get(student, 'desired_work_type')">
                        <i class="material-icons icon-18pt mr-8pt">timelapse</i>Seeking {{ student.desired_work_type }}
                      </h6>
                      <h6 class="icon-align" v-if="get(student, 'desired_work_time')">
                        <i class="material-icons icon-18pt mr-8pt">access_time</i>Available at
                        {{ student.desired_work_time }}
                      </h6>
                      <h6 class="icon-align" v-if="get(student, 'desired_work_day')">
                        <i class="material-icons icon-18pt mr-8pt">event</i>Available on
                        {{ student.desired_work_day }}
                      </h6>
                      <h6 class="icon-align" v-if="get(student, 'interested_facility_types')">
                        <i v-if="!student.interested_facility_types.length">Intested:: No facility interests added.</i>
                        <ul v-else>
                          <li v-for="(facility, index) in student.interested_facility_types" :key="index">
                            <i class="material-icons icon-18pt mr-8pt">check_circle</i> Facility Interest:
                            {{ facility }}
                          </li>
                        </ul>
                      </h6>
                    </div>
                  </div>
                </b-collapse>

                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="refundReqs.length">
          <div class="card-body">
            <div class="flex">
              <div class="d-flex align-items-center mb-1">
                <h5 class="card-title fw600">Requested Refunds</h5>
              </div>

              <div class="flex">
                <b-table
                  :fields="refundTableColumns"
                  :items="refundReqs"
                  :busy="isLoading"
                  head-variant="light"
                  :class="isMobSmallScreen ? '' : 'table-nowrap'"
                  class="clickable-item"
                  hover
                  responsive
                  no-local-sorting
                  @row-clicked="onRowClick"
                >
                  <template #cell(amount)="data"> {{ formatAmount(data.item.amount) }}</template>

                  <template #cell(review_date)="data">
                    <strong> {{ data.item.review_date ? formatFullDate(data.item.review_date) : '' }} </strong>
                  </template>

                  <template #cell(created_at)="data">
                    <strong> {{ formatFullDate(data.item.created_at) }} </strong>
                  </template>

                  <template #cell(status)="data">
                    <b-badge
                      :class="
                        'border border-' +
                          (data.item.status === REFUND_STATUSES.APPROVED
                            ? `success text-success`
                            : data.item.status === REFUND_STATUSES.PENDING
                            ? 'warning text-warning'
                            : data.item.status === REFUND_STATUSES.DECLINED
                            ? `danger text-danger`
                            : 'black')
                      "
                      variant="none"
                      >{{
                        data.item.status === REFUND_STATUSES.APPROVED
                          ? 'Approved'
                          : data.item.status === REFUND_STATUSES.PENDING
                          ? 'Pending'
                          : data.item.status === REFUND_STATUSES.CANCELLED
                          ? 'Cancelled'
                          : 'Declined'
                      }}</b-badge
                    >
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="flex">
              <div class="d-flex align-items-center mb-1">
                <h5 class="card-title fw600">Registrations</h5>
              </div>

              <div class="flex">
                <b-table
                  :fields="tableColumns"
                  :items="registeredClasses"
                  :busy="areClassesLoading"
                  head-variant="light"
                  :class="isMobSmallScreen ? '' : 'table-nowrap'"
                  @row-clicked="openRegProfile"
                  hover
                  responsive
                  no-local-sorting
                >
                  <template #cell(menu)="data">
                    <div class="text-center">
                      <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                        <template #button-content>
                          <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                          <span class="sr-only">Menu</span>
                        </template>

                        <b-dropdown-item class="reg-table-item" @click.prevent="openAddPaymentModal(data.item)">
                          <i style="font-size: 14px" class="fas fa-hand-holding-usd text-info"></i>Add Payment
                        </b-dropdown-item>

                        <b-dropdown-item class="reg-table-item" @click.prevent="openAddCommentModal(data.item)">
                          <i style="font-size: 14px" class="fas fa-comment text-info"></i
                          >{{ data.item.comment ? 'View Notes' : 'Add Notes' }}
                        </b-dropdown-item>

                        <!-- <b-dropdown-item
                          v-if="get(data, 'item.registration_refunds[0].status') !== REFUND_STATUSES.PENDING"
                          class="reg-table-item"
                          @click.prevent="openReqRefundPaymentModal(data.item)"
                        >
                          <i style="font-size: 18px" class="material-icons text-info mr-1">attach_money</i>Request
                          Payment Refund
                        </b-dropdown-item> -->

                        <b-dropdown-item
                          class="reg-table-item"
                          @click.prevent="
                            () => {
                              data.item.status === REGISTRATION_TYPES.ACTIVE
                                ? cancelRegistration(data.item)
                                : reactivateRegistration(data.item.id);
                            }
                          "
                        >
                          <i
                            class="material-icons mr-1"
                            :class="data.item.status === REGISTRATION_TYPES.CANCELLED ? 'text-success' : 'text-danger'"
                            >{{
                              data.item.status === REGISTRATION_TYPES.CANCELLED ? 'check_circle_outline' : 'block'
                            }}</i
                          >{{
                            data.item.status === REGISTRATION_TYPES.ACTIVE
                              ? 'Cancel Registration'
                              : 'Reactivate Registration'
                          }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                  <template #cell(class_enrolled)="data"> #{{ data.item.class_enrolled.id }} </template>
                  <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        {{ data.item.id }}
                      </div>
                      <div @click.stop v-if="data.item.comment">
                        <md-icon
                          @click.native.prevent="openAddCommentModal(data.item)"
                          style="font-size: 18px"
                          class="ml-2 clickable-item comment-hover"
                          >comment</md-icon
                        >
                      </div>
                    </div>
                  </template>
                  <template #cell(program)="data"> {{ data.item.program.title }}</template>
                  <template #cell(start_date)="data">
                    {{
                      data.item.class_enrolled.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED
                        ? formatDateTime(data.item.class_enrolled.start_date)
                        : 'Self-Paced'
                    }}</template
                  >

                  <template #cell(status)="data">
                    <b-badge
                      :class="
                        `border border-${
                          data.item.status === REGISTRATION_TYPES.CANCELLED ? 'danger' : 'success'
                        } text-${data.item.status === REGISTRATION_TYPES.CANCELLED ? 'danger' : 'success'}
                        
                      }`
                      "
                      variant="none"
                      >{{ $t(`studentRegStatus.${data.item.status}`) }}
                    </b-badge>
                  </template>

                  <template #head(actions)="">
                    <span></span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="flex">
              <div class="d-flex align-items-center mb-1">
                <h5 class="card-title fw600">Payments</h5>
              </div>

              <div class="flex">
                <b-table
                  :fields="paymentTableColumns"
                  :items="payments"
                  :busy="arePaymentsLoading"
                  head-variant="light"
                  hover
                  :class="isMobSmallScreen ? '' : 'table-nowrap'"
                  responsive
                  no-local-sorting
                >
                  <template #cell(status)="data">
                    <b-badge
                      :class="
                        `border border-${TRANSACTION_STATUSES_COLORS[data.item.status]} text-${
                          TRANSACTION_STATUSES_COLORS[data.item.status]
                        }`
                      "
                      variant="none"
                      >{{ $t(`transactionStatuses.${data.item.status}`) }}
                    </b-badge>
                  </template>

                  <template #cell(type)="data"> {{ $t(`transactionTypes.${data.item.type}`) }} </template>

                  <template #cell(amount)="data">{{ formatAmount(data.item.amount) }}</template>

                  <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
                  <template #head(actions)="">
                    <span></span>
                  </template>
                  <template #cell(actions)="data">
                    <a
                      href="#"
                      class="svg-icon mr-2"
                      v-if="data.item.type === TRANSACTION_STATUSES.MANUAL"
                      @click.prevent="openAddPaymentModal(data.item, true)"
                      v-b-popover.hover.left
                      title="Edit Payment"
                    >
                      <md-icon class="text-info">edit</md-icon>
                    </a>
                    <a
                      href="#"
                      class="svg-icon mr-2"
                      v-if="data.item.type === TRANSACTION_STATUSES.MANUAL"
                      @click.prevent="deletePayment(data.item.id)"
                      v-b-popover.hover.left
                      title="Remove Payment"
                    >
                      <md-icon class="text-info">delete</md-icon>
                    </a>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-payment-modal
      :show-modal="showAddPaymentModal"
      @close="hideAddPaymentModal"
      :payment="payment"
      :modalUpdate="modalUpdate"
    />

    <add-comment-modal
      :show-modal="showAddCommentModal"
      :reg="payment"
      @close="hideAddCommentModal"
      @commentUpdated="onCommentUpdate"
    />

    <comment-popover :show-modal="showCommentModal" @close="hideCommentModal" :comment="get(payment, 'comment')" />

    <reg-status-modal :show-modal="showRegStatusModal" @close="hideRegStatusModal" :registration="registration" />
    <req-refund-payment-modal
      :show-modal="showReqRefundPaymentModal"
      @close="hideReqRefundPaymentModal"
      :payment="payment"
      :modalUpdate="modalUpdate"
    />
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  formatFullDate,
  getLangOptions,
  getHumanReadableCommaSepStr,
  formatDateTime,
  stdPlanToPayOptions,
  formatDateFullYear,
} from '../../../common/utils';
import AddPaymentModal from './AddPaymentModal.vue';
import AddCommentModal from './AddCommentModal.vue';
import CommentPopover from './CommentPopover.vue';
import ReqRefundPaymentModal from './ReqRefundPaymentModal.vue';
import RegStatusModal from '../Classes/RegisteredStudents/RegStatusModal.vue';
import AmountFormatter from '@/mixins/amount-formatter';

import { get, keyBy } from 'lodash';
import moment from 'moment';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import {
  USER_ROLES,
  USER_ROLE_TYPES,
  FACILITY_TYPES,
  ETHNICITY_OPTIONS,
  TRANSACTION_STATUSES_COLORS,
  TRANSACTION_STATUSES,
  CLASS_SCHEDULE_TYPES,
  REGISTRATION_TYPES,
  REFUND_STATUSES,
} from '../../../common/constants';
import UserAvatar from '../../../components/User/UserAvatar.vue';

export default {
  components: { UserAvatar, AddPaymentModal, RegStatusModal, ReqRefundPaymentModal, AddCommentModal, CommentPopover },
  extends: Page,
  mixins: [AmountFormatter],

  data() {
    return {
      CLASS_SCHEDULE_TYPES,
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      title: 'Student Profile',
      //studentsListLinkName: '',
      careerInfoVisible: false,
      TRANSACTION_STATUSES_COLORS,
      TRANSACTION_STATUSES,
      isLoading: true,
      isLastContactLoading: true,
      student: {},

      // TODO: Check 'Cats Acceptable' Certification.
      certificationTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false, showText: 'Show License' },
        { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
        { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
        { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
      ],
      ethnicity: {},
      planToPay: null,

      ethnicityOptions: ETHNICITY_OPTIONS,
      showDoc: { cna_license_no: false },
      certifications: [],
      records: [],
      careerPreferences: [],
      stdCountLoading: true,
      schoolStdCount: 0,
      sections: [],
      showDocModal: false,
      selectedDocTitle: '',
      selectedDocSrc: '',
      showContactModal: false,
      langOptions: [],
      FACILITY_TYPES,
      registeredClasses: [],
      areClassesLoading: false,
      lastContacted: null,
      payments: [],
      arePaymentsLoading: false,
      totalPayments: 0,
      showAddPaymentModal: false,
      showAddCommentModal: false,
      showCommentModal: false,
      payment: null,
      modalUpdate: false,
      showRegStatusModal: false,
      REGISTRATION_TYPES,
      registration: {},
      showReqRefundPaymentModal: false,
      REFUND_STATUSES,
      refundReqs: [],
      windowWidth: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobSmallScreen() {
      return this.windowWidth <= 460;
    },
    formatBirthDate() {
      if (this.student.birthday) {
        return formatDateFullYear(this.student.birthday);
      }
      return '';
    },
    tableColumns() {
      return [
        {
          key: 'menu',
          tdClass: 'text-left',
        },
        { key: 'id', label: 'Id', tdClass: 'clickable-item' },
        { key: 'program', label: 'Program', tdClass: 'clickable-item' },
        { key: 'class_enrolled', label: 'Enrolled Class', tdClass: 'clickable-item' },
        // { key: 'comment', label: 'Notes', tdClass: 'clickable-item' },

        {
          key: 'start_date',
          label: 'Start Date',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatDateTime(value) : value),
        },
        {
          key: 'created_at',
          label: 'Registered On',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatDateTime(value) : value),
        },
        {
          key: 'status',
          label: 'status',
        },
      ];
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.title, active: true },
      ];
    },

    lastContactedInMonth() {
      if (!this.lastContacted) return false;

      const lastContact = moment(this.lastContacted);
      const diff = moment().diff(lastContact, 'months', true);
      return diff <= 1;
    },
    paymentTableColumns() {
      return [
        { key: 'id', label: 'Ref. ID' },
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },

    refundTableColumns() {
      return [
        { key: 'amount', label: 'Amount' },
        { key: 'review_date', label: 'Reviewed On' },
        { key: 'created_at', label: 'Requested On' },
        { key: 'status', label: 'Status' },
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getStudent', 'getRegisteredClasses', 'updateStdRegStatus']),
    ...mapActions('payoutAcc', ['getPayments', 'deleteManuallyPayment']),
    ...mapActions('refunds', ['getAllRefundsReqsForStudent']),
    formatFullDate,
    formatDateTime,
    get,
    stdPlanToPayOptions,

    goBack() {
      this.$router.go(-1);
    },
    hideRegStatusModal(no) {
      this.showRegStatusModal = false;
      if (!no) {
        this.fetchRegisteredClasses();
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    cancelRegistration(registration) {
      this.showRegStatusModal = true;
      this.registration = registration;
    },
    async reactivateRegistration(id) {
      const data = {
        status: REGISTRATION_TYPES.ACTIVE,

        student_registration: id,
      };

      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to reactivate registration for this student?`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updateStdRegStatus(data);
          this.fetchRegisteredClasses();
        }
      } catch (e) {
        //
      }
    },
    openReqRefundPaymentModal(payment) {
      this.payment = payment;
      this.showReqRefundPaymentModal = true;
    },
    hideReqRefundPaymentModal() {
      this.showReqRefundPaymentModal = false;
      this.fetchRegisteredClasses();
      this.fetchRefundReqs();
    },
    openAddPaymentModal(payment, update = false) {
      this.payment = payment;
      this.modalUpdate = update;
      this.showAddPaymentModal = true;
    },
    hideAddPaymentModal() {
      this.showAddPaymentModal = false;
      this.fetchPayments();
    },
    openAddCommentModal(reg) {
      this.payment = reg;
      this.showAddCommentModal = true;
    },
    hideAddCommentModal() {
      this.showAddCommentModal = false;
    },
    onCommentUpdate(data) {
      const reg = this.registeredClasses.find(std => std.id === data.id);
      if (reg) reg.comment = data.comment;
    },
    openCommentModal(reg) {
      this.payment = reg;
      this.showCommentModal = true;
    },
    hideCommentModal() {
      this.showCommentModal = false;
      this.fetchRegisteredClasses();
    },
    openRegProfile(data) {
      this.$router.push({
        name: 'manage-registration-profile',
        params: { id: data.id, backTo: 'schl-student-profile' },
      });
    },
    async deletePayment(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected payment.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteManuallyPayment(id);
          this.fetchPayments();
          this.makeToast({ variant: 'success', msg: 'Payment Deleted Successfully!' });
        }
      } catch (e) {
        // USE ERROR
      }
    },
    async fetchRegisteredClasses() {
      this.areClassesLoading = true;

      const response = await this.getRegisteredClasses({
        student_id: this.$route.params.id,
      });
      this.registeredClasses = response.data;

      this.areClassesLoading = false;
    },

    async fetchPayments() {
      this.arePaymentsLoading = true;

      const response = await this.getPayments({
        student: this.$route.params.id,
      });

      this.payments = response.data;
      this.arePaymentsLoading = false;
    },

    async fetchRefundReqs() {
      this.arePaymentsLoading = true;

      const response = await this.getAllRefundsReqsForStudent({
        student_id: this.$route.params.id,
      });

      this.refundReqs = response.data;
      this.arePaymentsLoading = false;
    },

    onRowClick(item) {
      this.$router.push({
        name: 'refund-details',
        params: {
          id: item.id,
        },
      });
    },

    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getStudent(this.$route.params.id)).data;

      // Redirect paused/incomplete/inactive profile to main candidates page.

      resp.desired_work_type = getHumanReadableCommaSepStr(
        resp.desired_work_type.map(type => this.$t(`studentWorkTypeOpts.${type}`)),
        'or'
      );
      resp.desired_work_time = getHumanReadableCommaSepStr(
        resp.desired_work_time.map(time => this.$t(`studentWorkTimeOpts.${time}`))
      );
      resp.desired_work_day = getHumanReadableCommaSepStr(
        resp.desired_work_day.map(day => this.$t(`jobWorkDays.${day}`))
      );
      const arr = getLangOptions();
      const langPreference = keyBy(arr, 'value');
      resp.lang_preferences = resp.lang_preferences.map(lang => langPreference[lang].name);

      resp.interested_facility_types = resp.interested_facility_types.map(type => this.FACILITY_TYPES[type]);
      if (resp.ethnicity === 'other') {
        this.ethnicity['text'] = resp.ethnicity_other;
      } else {
        this.ethnicity = this.ethnicityOptions.find(et => et.value === resp.ethnicity);
      }

      this.student = resp;

      this.isLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    if (this.$route.params.id) {
      await this.fetchStudent();
      this.fetchPayments();
      this.fetchRegisteredClasses();
      this.fetchRefundReqs();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.reg-table-item {
  font-size: 14px !important;
  margin-bottom: 0 !important;
}
.comment-hover:hover {
  color: var(--secondary-color);
}
</style>
