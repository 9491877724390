<template>
  <div :class="containerClass">
    <page-header
      title="Configure Location Settings"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Customize and configure details for your available campuses.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        :label="$t('schoolMsgs.school')"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="(school) => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
          @input="prefillLogo(school)"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator title="Manage Location/Campus" />

      <div class="d-flex flex-wrap justify-content-end">
        <b-btn
          variant="primary"
          :to="{ name: 'add-location', query: { ...(school && { school_id: school }) } }"
          style="width: 150px"
          type="submit"
          class="btn-normal mb-2"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Add</span>
        </b-btn>
      </div>

      <div class="row mb-lg-12pt" v-if="campuses.length">
        <draggable class="col-sm-12 draggable-cursor" @start="dragging = true" @end="updateOrder" :list="campuses">
          <div v-for="(item, index) in campuses" :key="index">
            <campus-card
              :image="item.image"
              :name="item.name"
              :email="item.email"
              :index="item.id"
              @removeCampus="removeCampus"
            />
          </div>
        </draggable>
      </div>
      <b-card
        v-else-if="
          (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN && school) ||
          get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
        "
        class="text-center"
      >
        <i> There isn't any location added. Please add if you have any. </i>
      </b-card>

      <b-form-invalid-feedback
        class="mb-lg-12pt"
        v-if="(!$v.campuses.required || !$v.campuses.minLength) && $v.campuses.$dirty"
        :state="(!$v.campuses.required || !$v.campuses.minLength) && $v.campuses.$dirty ? false : null"
        >There isn't any location added. Please add a location if your school has multiple
        campuses.</b-form-invalid-feedback
      >
    </b-form>
  </div>
</template>

<style lang="css" scoped>
</style>

<script>
import { get, isEmpty, map } from 'lodash';

import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS } from '@/common/constants';
import { requiredIf, required, minLength } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import CampusCard from './CampusCard.vue';
import vSelect from 'vue-select';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
export default {
  components: {
    PageHeader,
    FmvAvatar,
    draggable,
    CampusCard,
    vSelect,
    PageSeparator,
  },
  extends: Page,
  data() {
    return {
      campuses: [],

      SCHOOL_PAYMENT_PLANS,
      title: 'Configure Locations',

      school: null,
      dragging: false,

      options: [
        { item: 'stats', name: 'Stats' },
        { item: 'feature', name: 'Feature' },
      ],

      areSchoolsLoading: false,
      allSchools: [],
      isLoading: false,

      USER_ROLES,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      campuses: { required, minLength: minLength(1) },
      school: {
        required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return this.isLoading || this.areSchoolsLoading;
    },
  },

  methods: {
    ...mapActions('school', ['getAllSchools', 'getSchool']),
    ...mapActions('webConfig', ['getCampusesConfig', 'updateCampusesOrder', 'deleteCampus']),
    get,
    isEmpty,

    prefillLogo(schoolId) {
      this.fetchBasicConfig(schoolId);
    },
    async removeCampus(index) {
      if (index) {
        try {
          await this.deleteCampus(index);
          if (this.school) {
            this.fetchBasicConfig(this.school);
          } else {
            this.fetchBasicConfig();
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({
        sch_payment_plan__title: `${this.SCHOOL_PAYMENT_PLANS.ADVANCED},${this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT}`,
      });
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },
    async fetchBasicConfig(school_id = null) {
      let resp;
      if (school_id) {
        resp = await this.getCampusesConfig({
          school_id: school_id,
        });
      } else {
        resp = await this.getCampusesConfig({});
      }

      this.campuses = resp;
    },
    async updateOrder() {
      this.dragging = false;
      const data = {
        school:
          get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
            ? this.school
            : this.getLoggedInUser.linked_entity.id,
        campus_location: map(this.campuses, (id, order_num) => ({
          id: id.id,
          order_num: order_num + 1,
        })),
      };
      try {
        await this.updateCampusesOrder(data);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchBasicConfig();
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }
    if (
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
      get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
    ) {
      (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
    }
    try {
      if (
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
        get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
      ) {
        this.fetchBasicConfig();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  },
};
</script>
