<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage pre-enrollment requests of students."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Pre-enrollments"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
                <b-btn
                  variant="secondary"
                  @click.prevent="updateShowFilters(true)"
                  :class="isTabSmallScreen ? 'mb-1' : 'ml-2'"
                  v-if="!isTabSmallScreen && !showFilters"
                  size="sm"
                >
                  <i class="fas fa-sliders-h mr-1" />Show {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn
                  variant="light"
                  class="ml-2"
                  @click.prevent="updateShowFilters(false)"
                  v-if="!isTabSmallScreen && showFilters"
                  size="sm"
                >
                  <i class="fas fa-eye-slash mr-1" />Hide {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn
                  variant="light"
                  class="ml-2"
                  @click.prevent="clearFilters"
                  v-if="!isTabSmallScreen && areFiltersApplied"
                  size="sm"
                >
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-7 px-0" :class="isTabSmallScreen ? 'mt-2 mb-2' : 'mx-0'">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="title"
                    :value="program"
                    :reduce="program => program.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="onFilterApplied"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                          <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                          <md-icon v-else>local_library</md-icon>
                        </fmv-avatar>
                        <span>{{ option.title }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ truncate(option.title, { length: 20 }) }}
                    </template>
                  </v-select>
                </div>
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <!--
                <b-btn :to="{ name: 'add-student' }" exact variant="primary" v-text="$t('studentMsgs.addStudent')" /> -->
              </div>
              <div v-if="isTabSmallScreen" :style="{ marginLeft: '13px' }">
                <b-btn
                  variant="secondary"
                  @click.prevent="updateShowFilters(true)"
                  class="mb-1"
                  v-if="!showFilters"
                  size="sm"
                >
                  <i class="fas fa-sliders-h mr-1" />Show {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn variant="light" @click.prevent="updateShowFilters(false)" v-if="showFilters" size="sm">
                  <i class="fas fa-eye-slash mr-1" />Hide {{ !areFiltersApplied ? 'Filters' : '' }}
                </b-btn>
                <b-btn variant="light" class="ml-2" @click.prevent="clearFilters" v-if="areFiltersApplied" size="sm">
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div>
            </div>
          </div>
          <transition name="slide">
            <div v-if="showFilters" class="mt-2 ml-3 mr-1">
              <div class="row mb-2 mx-0 w-100 mt-2">
                <div class="col-sm-4 col-md-4 mb-2 px-1" :class="isTabSmallScreen ? 'mr-2' : ''">
                  <v-select
                    label="id"
                    :options="allLocations"
                    class="form-control v-select-custom"
                    :value="location"
                    :reduce="lc => lc.id"
                    placeholder="Select Location"
                    :loading="areLocationsLoading"
                    @input="filterLocations"
                    :filter-by="filterByLocation"
                  >
                    <template slot="option" slot-scope="option"> {{ get(option, 'city') }} </template>
                    <template slot="selected-option" slot-scope="option"> {{ get(option, 'city') }} </template>
                  </v-select>
                </div>
                <div v-if="!isTabSmallScreen" class="col-lg d-flex flex-wrap justify-content-end">
                  <div class="col-lg-7 px-0 mx-0">
                    <date-picker
                      v-model="dateRange"
                      format="MMMM DD, YYYY"
                      valueType="YYYY-MM-DD"
                      style="width: 100%"
                      id="date"
                      lang="en"
                      placeholder="Filter by Start Date Range"
                      range
                      @input="filterByDateRange"
                    ></date-picker>
                  </div>
                </div>
                <div v-else class="col-lg-7 px-0 mt-2 mb-2 ml-1 w-full" style="margin-right: 13px;">
                  <date-picker
                    class="filter-datepicker"
                    v-model="dateRange"
                    format="MMMM DD, YYYY"
                    valueType="YYYY-MM-DD"
                    style="width: 100%"
                    id="dateRange"
                    lang="en"
                    placeholder="Filter by Start Date Range"
                    range
                    @input="filterByDateRange"
                  ></date-picker>
                </div>
              </div>
            </div>
          </transition>
          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="students"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
            @sort-changed="onSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(menu)="data">
              <div class="text-center">
                <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                  <template #button-content>
                    <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                    <span class="sr-only">Menu</span>
                  </template>

                  <b-dropdown-item
                    @click.prevent="resend(data.item.id)"
                    v-if="data.item.status === STD_PRE_ENROLL_STATUSES.APPROVED"
                  >
                    <md-icon class="text-info">replay</md-icon> Resend Email
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="copyUrl(data.item)"
                    v-if="data.item.status === STD_PRE_ENROLL_STATUSES.APPROVED"
                  >
                    <md-icon class="text-info">content_copy</md-icon> Copy Invite Url
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="updateStatus(data.index, 'disapproved')"
                    v-if="
                      [STD_PRE_ENROLL_STATUSES.APPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(data.item.status)
                    "
                  >
                    <md-icon class="text-danger">block</md-icon> Disapprove Request
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="updateStatus(data.index, 'approved')"
                    v-if="
                      [STD_PRE_ENROLL_STATUSES.DISAPPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(data.item.status)
                    "
                  >
                    <md-icon class="text-success">check</md-icon> Approve Request
                  </b-dropdown-item>

                  <b-dropdown-item :href="`mailto:${data.item.email}`">
                    <md-icon class="text-info">email</md-icon> Email Student
                  </b-dropdown-item>

                  <b-dropdown-item :href="`tel:${data.item.phone}`">
                    <md-icon class="text-info">phone</md-icon> Call Student
                  </b-dropdown-item>

                  <b-dropdown-item class="reg-table-item" @click.prevent="openAddCommentModal(data.item)">
                    <md-icon class="text-info">comment</md-icon>
                    {{ data.item.comment ? 'View Notes' : 'Add Notes' }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #cell(name)="data"
              ><strong>{{ data.item.first_name }} {{ data.item.last_name }}</strong>

              <span @click.stop v-if="data.item.comment">
                <md-icon
                  @click.native.prevent="openAddCommentModal(data.item)"
                  style="font-size: 18px"
                  class="ml-2 clickable-item comment-hover"
                  >comment</md-icon
                >
              </span></template
            >
            <template #cell(status)="data">
              <b-badge
                :class="
                  `border border-${STD_PRE_ENROLL_STATUSES_COLORS[data.item.status]} text-${
                    STD_PRE_ENROLL_STATUSES_COLORS[data.item.status]
                  }`
                "
                variant="none"
                >{{ $t(`preEnrollStatuses.${data.item.status}`) }}
              </b-badge>
            </template>
            <template #cell(created_at)="data"> {{ formatDateTime(data.item.created_at) }} </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
    <add-comment-modal
      :show-modal="showAddCommentModal"
      :reg="comment"
      is-preenroll-comment
      @close="hideAddCommentModal"
      @commentUpdated="onCommentUpdate"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import AddCommentModal from './AddCommentModal.vue';
import { formatDateTime } from '../../../common/utils';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, STD_PRE_ENROLL_STATUSES_COLORS, STD_PRE_ENROLL_STATUSES } from '../../../common/constants';
import vSelect from 'vue-select';
import { get, truncate } from 'lodash';
import { FmvAvatar } from 'fmv-avatar';
import DatePicker from 'vue2-datepicker';

export default {
  components: { MdIcon, PageHeader, Pagination, AddCommentModal, vSelect, FmvAvatar, DatePicker },
  extends: Page,

  data() {
    return {
      title: 'Manage Pre-enrollments',
      STD_PRE_ENROLL_STATUSES_COLORS,
      STD_PRE_ENROLL_STATUSES,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      isResending: false,
      searchTerm: '',
      ordering: 'status',
      showAddCommentModal: false,
      comment: null,
      program: null,
      location: null,
      areProgramsLoading: false,
      areLocationsLoading: false,
      allPrograms: [],
      allLocations: [],
      dateRange: [],
      showFilters: false,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Pre-enrollments', active: true },
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    areFiltersApplied() {
      return this.program || this.location || this.dateRange.length;
    },
    tableColumns() {
      return [
        {
          key: 'menu',
          tdClass: 'text-left',
        },
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },
        { key: 'status', label: 'Status', tdClass: 'clickable-item', sortable: true },

        { key: 'email', label: this.$t('authMsgs.email'), tdClass: 'clickable-item' },
        { key: 'phone', label: this.$t('studentMsgs.phone'), tdClass: 'clickable-item' },
        { key: 'created_at', label: 'Requested On', tdClass: 'clickable-item' },
      ];
    },
  },

  methods: {
    get,
    truncate,
    ...mapActions('preEnrollForm', ['getPreEnrolledStds', 'updatePreEnrollStatus', 'resendPreEnrollEmail']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('location', ['getAllLocations']),

    formatDateTime,
    filtersApplied() {
      this.$router.replace({
        name: 'schl-pre-enroll-students-list',
        query: {
          ...(this.program && { program: this.program }),
          ...(this.location && { location: this.location }),
          ...(!!this.dateRange.length &&
            this.dateRange[0] &&
            this.dateRange[1] && { start_date: this.dateRange[0] + ',' + this.dateRange[1] }),
        },
      });
    },
    onRowClicked(data) {
      this.$router.push({
        name: 'schl-pre-enroll-student-profile',
        params: { id: data.id },
        query: { program: data.class_enrolled.program },
      });
    },
    async resend(id) {
      this.isResending = true;
      try {
        await this.resendPreEnrollEmail(id);
        this.fetchPreEnrolledStudents();

        this.makeToast({ variant: 'success', msg: 'Resent email.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async copyUrl(data) {
      try {
        const urlLink = `${this.getLoggedInUser.linked_entity.subdomain.subdomain_url}/registration?pre_enrollment_id=${data.id}`;
        await navigator.clipboard.writeText(urlLink);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);

        this.makeToast({ variant: 'success', msg: 'Invite link copied.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async updateStatus(index, statusText) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to mark the status as ${statusText}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updatePreEnrollStatus({ id: this.students[index].id, data: { status: statusText } });
          this.fetchPreEnrolledStudents();
          this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async fetchPreEnrolledStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getPreEnrolledStds({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.program && { prg_id: this.program }),
        ...(this.location && { location: this.location }),
        ...(!!this.dateRange.length &&
          this.dateRange[0] &&
          this.dateRange[1] && { start_date: this.dateRange[0] + ',' + this.dateRange[1] }),
        ...params,
      });
      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchPreEnrolledStudents(pageNum);
    },
    clearFilters() {
      this.searchTerm = '';
      this.program = this.location = null;
      this.dateRange = [];
      this.filtersApplied();
      this.fetchPreEnrolledStudents();
    },
    updateShowFilters(value) {
      this.showFilters = value;
    },
    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPreEnrolledStudents();
    },
    onFilterApplied(value) {
      this.program = value;
      this.filter();
    },
    filterByLocation(option, label, search) {
      let searchLower = search.toLowerCase();
      this.location = option;
      return (
        get(option, 'address', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'city', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'state', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'zipcode', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'country', '')
          .toLowerCase()
          .indexOf(searchLower) > -1
      );
    },
    filter() {
      this.filtersApplied();
      this.fetchPreEnrolledStudents();
    },
    filterLocations(value) {
      this.location = value;
      this.filter();
    },
    filterByDateRange(value) {
      this.dateRange = value;
      this.filter();
    },
    async fetchLocation() {
      this.areLocationsLoading = true;

      const response = await this.getAllLocations({});
      this.allLocations = response.data;
      this.areLocationsLoading = false;
    },
    onSearch() {
      this.debouncedSearchPreEnrolledStudents(this);
    },

    debouncedSearchPreEnrolledStudents: debounce(vm => {
      vm.fetchPreEnrolledStudents();
    }, 500),

    openAddCommentModal(item) {
      this.comment = item;
      this.showAddCommentModal = true;
    },
    hideAddCommentModal() {
      this.showAddCommentModal = false;
      this.comment = null;
    },
    onCommentUpdate(data) {
      const student = this.students.find(std => std.id === data.id);
      if (student) student.comment = data.comment;
    },
  },

  async mounted() {
    this.fetchLocation();
    this.fetchPrograms();
    if (this.$route.query.program) {
      this.program = Number(this.$route.query.program);
    }
    if (this.$route.query.location) {
      this.location = Number(this.$route.query.location);
      this.showFilters = true;
    }
    if (this.$route.query.start_date) {
      const date = this.$route.query.start_date.split(',');
      this.dateRange = [date[0], date[1]];
      this.showFilters = true;
    }
    this.fetchPreEnrolledStudents();
  },
};
</script>
