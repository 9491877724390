<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div
            class="container page__container d-flex align-items-center"
            v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
          >
            <p class="backResult text-50">
              <router-link :to="{ name: 'classes-management-list' }" style="text-decoration: underline">
                <span class="material-icons icon-18pt text-80">navigate_before</span>
              </router-link>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>

              <b-row>
                <b-col md="2">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card" v-for="i in [1, 2, 3]" :key="i">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <h6>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </h6>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div
          class="container page__container d-flex align-items-center"
          v-if="getLoggedInUser.role !== USER_ROLES.STUDENT"
        >
          <p class="backResult text-50">
            <a
              v-if="$route.params.backTo"
              @click.prevent="goBack"
              class="clickable-item"
              style="text-decoration: underline"
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </a>
            <router-link
              v-else
              :to="{ name: 'classes-management-list', query: { registrations: registration.class_enrolled.id } }"
              style="text-decoration: underline"
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>
            </router-link>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <user-avatar
            size="7.3rem"
            :user="student.user"
            rounded
            hide-text
            class="mr-md-32pt mb-32pt mb-md-0 mobilemb10"
          />

          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4 style="margin-bottom: 0rem">{{ student.user.first_name }} {{ student.user.last_name }} Registration</h4>
            <div>Registered on: {{ formatDateTime(registration.created_at) }}</div>
            <div class="mt-2" v-if="registration.status === REGISTRATION_TYPES.CANCELLED">
              Registration Status:
              <b-badge class="mr-2" pill variant="danger">Canceled</b-badge>
            </div>

            <div class="mt-2" v-else-if="enrollStatus">
              Enrollment Status:
              <b-badge class="mr-2" pill :variant="enrollStatusColor[enrollStatus]">{{ $t(`studentEnrollStatus.${enrollStatus}`) }}</b-badge>
            </div>
            <!-- <div class="d-flex justify-content-between"> -->
            <b-dropdown text="Contact" variant="primary" class="mt-4">
              <b-dropdown-item :href="`mailto:${student.user.email}`"
                ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
              >
              <b-dropdown-item :href="`tel:${student.user.phone}`"
                ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
              >
            </b-dropdown>

            <b-dropdown text="Actions" variant="" class="ml-2 mt-4">
              <b-dropdown-item
                :to="{
                  name: 'schl-student-profile',
                  params: { id: student.id },
                }"
                ><i class="material-icons text-info mr-1">account_box</i>Show Profile</b-dropdown-item
              >
              <!-- <b-dropdown-item
                v-if="get(registration, 'registration_refunds[0].status') !== REFUND_STATUSES.PENDING"
                class="reg-table-item"
                @click.prevent="openReqRefundPaymentModal(registration)"
              >
                <i class="material-icons text-info mr-1">attach_money</i>Request Payment Refund
              </b-dropdown-item> -->
              <b-dropdown-item
                @click.prevent="
                  () => {
                    registration.status === REGISTRATION_TYPES.ACTIVE
                      ? (showRegStatusModal = true)
                      : reactivateRegistration();
                  }
                "
                ><i
                  class="material-icons mr-1"
                  :class="registration.status === REGISTRATION_TYPES.CANCELLED ? 'text-success' : 'text-danger'"
                  >{{ registration.status === REGISTRATION_TYPES.CANCELLED ? 'check_circle_outline' : 'block' }}</i
                >{{
                  registration.status === REGISTRATION_TYPES.ACTIVE ? 'Cancel Registration' : 'Reactivate Registration'
                }}</b-dropdown-item
              >

              <b-dropdown-item @click.prevent="openAddCommentModal(registration)">
                <i class="material-icons text-info mr-1">comment</i>
                {{ registration.comment ? 'Update Notes' : 'Add Notes' }}
              </b-dropdown-item>

              <b-dropdown-item @click.prevent="downloadCancelation(registration)">
                <i class="material-icons text-info mr-1">file_download</i>
                Export Cancellation/Refund Agreement
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
        <b-alert show variant="danger" v-if="registration.status === REGISTRATION_TYPES.CANCELLED">
          <strong> Cancellation Reason: </strong>
          <span>{{ registration.cancellation_reason }}</span>
        </b-alert>

        <div class="card" v-if="registration.comment">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Notes</h5>
            </div>

            <p v-html="toggleComment ? formattedText : truncate(formattedText, { length: 300 })"></p>
            <strong
              @click.prevent="() => (toggleComment = !toggleComment)"
              v-if="registration.comment.length > 300"
              class="clickable-item"
              >{{ toggleComment ? ' ... see less' : 'see more' }}</strong
            >
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Program Details</h5>
            </div>

            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">local_library</i
              ><b class="mr-1">Program Name:</b>
              {{ registration.program.title }}
            </h6>

            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">class</i><b class="mr-1">Class ID:</b>
              {{ registration.class_enrolled.id }}
            </h6>
            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">date_range</i
              ><b class="mr-1">Start Date:</b>
              {{
                registration.class_enrolled.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED
                  ? formatFullDate(registration.class_enrolled.start_date)
                  : 'Self-Paced'
              }}
            </h6>
            <h6 v-if="registration.class_enrolled.class_location">
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">location_on</i
              ><b class="mr-1">Class Location:</b>
              {{ registration.class_enrolled.class_location }}
            </h6>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Payment Details</h5>
            </div>

            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">attach_money</i
              ><b class="mr-1">Payment Option:</b>
              {{ $t(`stdRegPaymentOption.${registration.payment_option}`) }}
            </h6>
            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">attach_money</i
              ><b class="mr-1">Status:</b>
              <i v-if="isPayStatusLoading" class="mr-1">Loading</i>
              <span v-else class="mr-1"
                >{{ $t(`stdRegPaymentStatus.${paymentStatus}`) }}
                <span v-if="paymentStatus === STD_REG_PAY_STATUSES.PARTIAL_PAID"
                  >({{ $n(registration.program.application_fee, 'currency', 'en-US') }})</span
                ></span
              >
              <span v-if="registration.payment_option === STD_REG_PAY_OPTIONS.PAY_LATER">
                -
                <a href="#" @click.prevent="updatePayStatus" class="ml-1 text-primary"
                  >Mark as {{ paymentStatus === STD_REG_PAY_STATUSES.COMPLETE_PAID ? 'Unpaid' : 'Paid' }}</a
                >
              </span>
            </h6>

            <h6>
              <i class="material-icons icon-18pt mr-8pt" style="color: #b0bec5">attach_money</i
              ><b class="mr-1">Plan to Pay:</b>
              {{ $t(`planToPayOptions.${registration.plan_to_pay}`) }}
              <span v-if="registration.plan_to_pay === 'sponsored_employer_agency'">
                - {{ get(registration, 'extra.scholarship_name') }}
              </span>
              <md-icon
                @click.native.prevent="openPlanChangeModal(registration)"
                style="font-size: 15px"
                class="clickable-item"
                >edit</md-icon
              >
            </h6>

            <div class="my-2 p-2" v-if="payments.length">
              <b-table
                :fields="paymentPlanTableColumns"
                :items="payments"
                :busy="isLoading"
                head-variant="light"
                hover
                responsive
                no-local-sorting
              >
                <template #cell(status)="data">
                  <b-badge
                    variant="none"
                    :class="
                      (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                      (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                        ? 'border border-success text-success'
                        : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                        ? 'border border-warning text-warning'
                        : 'border border-danger text-danger'
                    "
                  >
                    <strong>{{
                      (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                      (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                        ? 'Paid'
                        : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                        ? 'Cancelled'
                        : 'Unpaid'
                    }}</strong>
                  </b-badge>
                </template>
                <template #cell(amount)="data"> ${{ $n(data.item.amount) }} </template>

                <template #cell(date)="data"> {{ formatFullDate(data.item.date) }} </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data">
                  <a
                    href="#"
                    v-if="data.item.status !== STUDENT_PAYMENT_TYPES.CANCELLED"
                    v-b-popover.hover.right
                    :title="data.item.status === STUDENT_PAYMENT_TYPES.PAID ? 'Mark as Unpaid' : 'Mark as Paid'"
                    @click.prevent="updateStatus(data.item)"
                  >
                    <md-icon
                      :class="data.item.status === STUDENT_PAYMENT_TYPES.PAID ? 'text-danger' : 'text-success'"
                      >{{ data.item.status === STUDENT_PAYMENT_TYPES.PAID ? 'block' : 'check_circle_outline' }}</md-icon
                    >
                  </a>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Student's Information</h5>
            </div>

            <div class="row">
              <b-form-group
                :label="$t('userMsgs.firstName')"
                label-for="firstname"
                label-class="form-label"
                class="col-md-6"
              >
                <b-form-input
                  id="firstname"
                  :placeholder="$t('userMsgs.firstName')"
                  :value="student.user.first_name"
                  disabled
                />
              </b-form-group>

              <b-form-group
                :label="$t('userMsgs.lastName')"
                label-for="lastname"
                label-class="form-label"
                class="col-md-6"
              >
                <b-form-input
                  id="lastname"
                  :placeholder="$t('userMsgs.lastName')"
                  :value="student.user.last_name"
                  disabled
                />
              </b-form-group>
            </div>

            <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
              <b-form-input
                id="email"
                :placeholder="$t('authMsgs.emailAddr')"
                type="email"
                :value="student.user.email"
                disabled
              />
            </b-form-group>
            <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
              <b-form-input
                id="phone"
                placeholder="(888) 689 - 1235"
                :value="student.user.phone"
                v-mask="'(###) ### - ####'"
                disabled
              />
            </b-form-group>
            <b-form-group label="Birth Date" label-for="birthday" label-class="form-label">
              <b-form-input id="birthday" :value="student.birthday" disabled />
            </b-form-group>
            <b-form-group
              label="Language Preference(s)"
              label-for="lang_preferences"
              label-class="form-label"
              v-if="staticFieldsConfig.lang_preferences !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="lang_preferences"
                class="form-control v-select-custom"
                label="name"
                :value="student.lang_preferences"
                :reduce="item => item.value"
                placeholder="Select Preferred Language"
                :options="langOptions"
                :clearable="false"
                multiple
                disabled
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Gender"
              label-for="gender"
              label-class="form-label"
              v-if="staticFieldsConfig.gender !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="gender"
                class="form-control v-select-custom"
                label="text"
                :value="student.user.gender"
                :reduce="item => item.value"
                placeholder="Select your gender"
                :options="GENDER_OPTIONS"
                :clearable="false"
                disabled
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Ethnicity"
              label-for="ethnicity"
              label-class="form-label"
              v-if="staticFieldsConfig.ethnicity !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="ethnicity"
                class="form-control v-select-custom"
                label="text"
                :value="student.ethnicity"
                :reduce="r => r.value"
                placeholder="Select your ethnicity"
                :options="ethnicityOptions"
                :clearable="false"
                disabled
              >
              </v-select>
              <b-form-input
                id="ethnicity_other"
                class="mt-1"
                placeholder="Please specify"
                :value="student.ethnicity_other"
                v-if="ethnicity === 'other'"
                disabled
                :clearable="false"
              />
            </b-form-group>

            <!-- Address Fields -->
            <b-form-group
              :label="$t('addressMsgs.address')"
              label-for="address"
              label-class="form-label"
              v-if="staticFieldsConfig.address !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input id="address" :placeholder="$t('addressMsgs.address')" :value="student.address" disabled />
            </b-form-group>

            <div class="row">
              <b-form-group
                :label="$t('addressMsgs.zipcode')"
                label-for="zipcode"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.zipcode !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="zipcode" :placeholder="$t('addressMsgs.zipcode')" :value="student.zipcode" disabled />
              </b-form-group>

              <b-form-group
                :label="$t('addressMsgs.city')"
                label-for="city"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.city !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="city" :placeholder="$t('addressMsgs.city')" :value="student.city" disabled />
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                :label="$t('addressMsgs.state')"
                label-for="state"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.state !== STATIC_FIELD_TYPE.DISABLED"
              >
                <b-form-input id="state" :placeholder="$t('addressMsgs.state')" :value="student.state" disabled />
              </b-form-group>

              <b-form-group
                :label="$t('addressMsgs.country')"
                label-for="country"
                label-class="form-label"
                class="col-md-6"
                v-if="staticFieldsConfig.country !== STATIC_FIELD_TYPE.DISABLED"
              >
                <v-select
                  id="country"
                  class="form-control v-select-custom"
                  label="text"
                  :value="student.country"
                  :reduce="country => country.value"
                  :placeholder="$t('addressMsgs.countryPlaceholder')"
                  disabled
                  :clearable="false"
                >
                </v-select>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Additional Information</h5>
            </div>

            <b-form-group v-if="staticFieldsConfig.student_ssn !== STATIC_FIELD_TYPE.DISABLED">
              <label class="form-label" for="student_ssn">Social Security Number</label>

              <b-form-input
                id="student_ssn"
                placeholder="Please enter your Social Security Number (SSN)"
                :value="student.student_ssn"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Occupation"
              label-for="occupation"
              label-class="form-label"
              v-if="staticFieldsConfig.occupation !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="occupation"
                placeholder="Please enter your occupation (if any)"
                :value="student.occupation"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Plan to Pay"
              label-for="plan_to_pay"
              label-class="form-label"
              v-if="staticFieldsConfig.plan_to_pay !== STATIC_FIELD_TYPE.DISABLED"
            >
              <div
                v-for="option in stdPlanToPayOptions(get(this.getLoggedInUser, 'linked_entity.tuition_fee_name'))"
                :key="option.value"
              >
                <div class="card p-3 mb-2" style="border-left: 0px" v-if="option.value === registration.plan_to_pay">
                  <div>
                    <i style="font-size: 23px" :class="`fas ${option.icon} text-primary mr-1 mb-1`" />
                    <span style="font-size: 16px">
                      {{ option.text }}
                    </span>
                  </div>

                  <span :class="option.description ? 'mt-2' : ''">
                    {{ option.description }}
                  </span>
                  <div v-if="option.value === 'sponsored_employer_agency'">
                    <v-select
                      id="sponsorship_select"
                      class="form-control v-select-custom my-2"
                      label="title"
                      :value="get(registration, 'extra.scholarship_name')"
                      :clearable="false"
                      disabled
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label="Registration Reason"
              label-for="registration_reason"
              label-class="form-label"
              v-if="staticFieldsConfig.registration_reason !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="registration_reason"
                class="form-control v-select-custom"
                label="text"
                :value="registration.registration_reason"
                :reduce="r => r.value"
                placeholder="Select the reason for registration"
                :options="STD_REG_REASON_OPTIONS"
                disabled
                :clearable="false"
              >
              </v-select>

              <b-form-input
                id="other_registration_reason"
                class="mt-1"
                placeholder="Please specify"
                :value="registration.other_registration_reason"
                disabled
                v-if="registration.registration_reason === 'other'"
              />
            </b-form-group>

            <b-form-group
              label="Have you been certified before?"
              label-for="certified_before"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="certified_before"
                v-model="student.certified_before"
                :options="YES_NO_OPTIONS"
                :aria-describedby="ariaDescribedby"
                disabled
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              :label="`How did you hear about the program?`"
              label-for="reference"
              label-class="form-label"
              v-if="staticFieldsConfig.reference !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="reference"
                class="form-control v-select-custom"
                label="text"
                :value="student.reference"
                :reduce="r => r.value"
                placeholder="How did you discover the program?"
                :options="STD_REG_REFERRAL_OPTIONS"
                disabled
                :clearable="false"
              >
              </v-select>

              <b-form-input
                id="other_reference"
                class="mt-1"
                placeholder="Please specify"
                :value="other_reference"
                v-if="student.reference === 'other'"
                disabled
              />
            </b-form-group>
            <b-form-group
              label="Would you like to receive job and career opportunities from top healthcare employers?"
              label-for="career_service_interest"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                id="career_service_interest"
                v-model="student.career_service_interest"
                :options="YES_NO_OPTIONS"
                :aria-describedby="ariaDescribedby"
                disabled
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              label="Resume"
              label-for="resume"
              label-class="form-label"
              class="row-align-items-center"
              v-if="staticFieldsConfig.resume_url !== STATIC_FIELD_TYPE.DISABLED"
            >
              <!-- TODO: Add resume download here -->
              <a
                href="#"
                @click.prevent="
                  downloadGivenFile({
                    fileUrl: student.resume_url,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                v-if="student.resume_url"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(student.resume_url, true) }}
              </a>
              <i v-else>No resume uploaded.</i>
            </b-form-group>

            <!-- Work Schedule Fields -->
            <b-form-group
              label="Desired Work Type"
              label-for="desired_work_type"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_type !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_type"
                placeholder="Select Desired Work Type"
                :value="student.desired_work_type"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Desired Work Time"
              label-for="desired_work_time"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_time !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_time"
                placeholder="Select Desired Work Time"
                :value="student.desired_work_time"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Desired Work Days"
              label-for="desired_work_day"
              label-class="form-label"
              v-if="staticFieldsConfig.desired_work_day !== STATIC_FIELD_TYPE.DISABLED"
            >
              <b-form-input
                id="desired_work_day"
                placeholder="Select Desired Work Days"
                :value="student.desired_work_day"
                disabled
              />
            </b-form-group>

            <b-form-group
              label="Work Travel"
              label-for="work_travel"
              label-class="form-label"
              v-if="staticFieldsConfig.work_travel !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="work_travel"
                class="form-control v-select-custom"
                label="text"
                :value="student.work_travel"
                :reduce="item => item.value"
                :options="workTravelOptions"
                placeholder="Select preferable miles to travel."
                disabled
                :clearable="false"
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Interested Facility Type(s)"
              label-for="interested_facility_types"
              label-class="form-label"
              v-if="staticFieldsConfig.interested_facility_types !== STATIC_FIELD_TYPE.DISABLED"
            >
              <v-select
                id="interested_facility_types"
                class="form-control v-select-custom"
                label="text"
                :value="student.interested_facility_types"
                :reduce="item => item.value"
                placeholder="Select the facility types the student is interested in working at"
                multiple
                disabled
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </div>
        </div>

        <div class="card" v-for="sec in registration.student_question_response" :key="sec.id">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">{{ sec.section.title }}</h5>
                </div>

                <i v-if="!sec.data.length">No questions in the section.</i>

                <div v-else>
                  <b-form-group
                    :label="ques.title"
                    label-class="form-label"
                    v-for="(ques, index) in sec.data"
                    :key="index"
                  >
                    <b-form-input
                      :placeholder="ques.placeholder"
                      v-if="ques.type === QUES_TYPES.SHORT_TEXT"
                      :value="ques.response.data"
                      disabled
                    />
                    <b-form-textarea
                      v-else-if="ques.type === QUES_TYPES.LONG_TEXT"
                      class="form-control"
                      rows="10"
                      :placeholder="ques.placeholder"
                      :value="ques.response.data"
                      disabled
                    ></b-form-textarea>
                    <v-select
                      v-else-if="ques.type === QUES_TYPES.SINGLE_SELECT"
                      class="form-control v-select-custom"
                      label="text"
                      disabled
                      :value="ques.response.data"
                      :reduce="ins => ins.value"
                      :options="get(ques, 'extra.options', [])"
                      :placeholder="ques.placeholder"
                      :clearable="false"
                    >
                    </v-select>
                    <v-select
                      v-if="ques.type === QUES_TYPES.MULTI_SELECT"
                      class="form-control v-select-custom"
                      label="text"
                      :value="ques.response.data"
                      :reduce="ins => ins.value"
                      :options="get(ques, 'extra.options', [])"
                      :placeholder="ques.placeholder"
                      multiple
                      disabled
                      :clearable="false"
                    >
                    </v-select>
                    <div v-else-if="ques.type === QUES_TYPES.FILE_TYPE">
                      <a
                        v-if="ques.response.data"
                        href="#"
                        @click.prevent="
                          downloadGivenFile({
                            fileUrl: ques.response.data,
                            removeTimestamp: true,
                          })
                        "
                        v-b-popover.hover.top="'Download'"
                        ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(ques.response.data, true) }}
                      </a>
                      <i v-else>No file uploaded.</i>
                    </div>

                    <b-form-radio-group
                      v-else-if="ques.type === QUES_TYPES.BOOLEAN"
                      v-model="ques.response.data"
                      :options="get(ques, 'extra.options', YES_NO_OPTIONS)"
                      disabled
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Terms and Conditions</h5>
            </div>

            <b-form-group label-class="form-label">
              <b-form-checkbox v-model="termsAndConditions" name="check-button" disabled>
                <span style="font-weight: initial" class="text-black"
                  >I understand and accept the
                  <a href="#" @click.prevent="showTermsModal = true" class="text-primary">terms and conditions</a
                  >.</span
                >
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Signature" label-for="signature" label-class="form-label">
              <h1 class="signature-font">{{ registration.signature }}</h1>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <PayToPlanModal :show-modal="showPlanChangeModal" @close="hidePlanChangeModal" :payment="payment" />

    <add-comment-modal
      :show-modal="showAddCommentModal"
      :showPreview="false"
      :reg="payment"
      @close="hideAddCommentModal"
      @commentUpdated="onCommentUpdate"
    />

    <reg-terms-modal
      :show-modal="showTermsModal"
      @close="showTermsModal = false"
      :registration-terms="registrationTerms"
    ></reg-terms-modal>

    <reg-status-modal :show-modal="showRegStatusModal" @close="hideRegStatusModal" :registration="registration" />
    <req-refund-payment-modal
      :show-modal="showReqRefundPaymentModal"
      @close="hideReqRefundPaymentModal"
      :payment="payment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { truncate, get, keyBy } from 'lodash';

import Page from '@/components/Page.vue';
import {
  formatFullDate,
  getLangOptions,
  getHumanReadableCommaSepStr,
  getWorkTravelOptions,
  formatDateTime,
  parseFileNameFromUrl,
  formatDateSimple,
  stdPlanToPayOptions,
} from '../../../../common/utils';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import {
  USER_ROLES,
  USER_ROLE_TYPES,
  FACILITY_TYPES,
  ETHNICITY_OPTIONS,
  STATIC_FIELD_TYPE,
  STD_REG_REASON_OPTIONS,
  YES_NO_OPTIONS,
  GENDER_OPTIONS,
  STD_REG_REFERRAL_OPTIONS,
  QUES_TYPES,
  STD_REG_PAY_STATUSES,
  STD_REG_PAY_OPTIONS,
  CLASS_SCHEDULE_TYPES,
  REGISTRATION_TYPES,
  STUDENT_PAYMENT_TYPES,
  REFUND_STATUSES,
} from '../../../../common/constants';
import UserAvatar from '../../../../components/User/UserAvatar.vue';
import RegTermsModal from '../../../SchoolPublicLanding/Programs/Registration/RegTermsModal.vue';
import RegStatusModal from './RegStatusModal.vue';
import ReqRefundPaymentModal from '../../Students/ReqRefundPaymentModal.vue';
import AddCommentModal from '../../Students/AddCommentModal.vue';
import vSelect from 'vue-select';
import PayToPlanModal from './PayToPlanModal.vue';

export default {
  components: {
    UserAvatar,
    RegTermsModal,
    RegStatusModal,
    ReqRefundPaymentModal,
    vSelect,
    AddCommentModal,
    PayToPlanModal,
  },
  extends: Page,
  name: 'RegistrationProfile',

  data() {
    return {
      CLASS_SCHEDULE_TYPES,
      STUDENT_PAYMENT_TYPES,
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      STATIC_FIELD_TYPE,
      STD_REG_REASON_OPTIONS,
      YES_NO_OPTIONS,
      GENDER_OPTIONS,
      STD_REG_REFERRAL_OPTIONS,
      QUES_TYPES,
      STD_REG_PAY_STATUSES,
      STD_REG_PAY_OPTIONS,

      title: 'Student Registration',
      //studentsListLinkName: '',

      isLoading: true,
      isLastContactLoading: true,
      isPayStatusLoading: false,
      termsAndConditions: true,
      student: {},
      registration: {},

      // TODO: Check 'Cats Acceptable' Certification.
      certificationTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false, showText: 'Show License' },
        { key: 'cpr_url', name: 'CPR Certification', downloadable: true },
        { key: 'first_aid_url', name: 'First Aid Certification', downloadable: true },
        { key: 'bls_url', name: 'BLS Certification (Life Support)', downloadable: true },
      ],
      ethnicity: null,
      ethnicityOptions: ETHNICITY_OPTIONS,
      showDoc: { cna_license_no: false },
      certifications: [],
      records: [],
      careerPreferences: [],
      stdCountLoading: true,
      schoolStdCount: 0,
      sections: [],
      showDocModal: false,
      selectedDocTitle: '',
      selectedDocSrc: '',
      showContactModal: false,
      langOptions: [],
      workTravelOptions: [],
      FACILITY_TYPES,

      staticFieldsConfig: {
        lang_preferences: null,
        desired_work_type: null,
        desired_work_time: null,
        desired_work_day: null,
        work_travel: null,
        interested_facility_types: null,
        student_ssn: null,
        reference: null,
        registration_reason: null,
        plan_to_pay: null,
        occupation: null,
        gender: null,
        ethnicity: null,
        address: null,
        zipcode: null,
        city: null,
        state: null,
        country: null,
        resume_url: null,
      },
      school: null,
      showTermsModal: false,
      registrationTerms: null,
      showRegStatusModal: false,
      REGISTRATION_TYPES,
      payments: [],
      paymentStatus: false,
      REFUND_STATUSES,
      showReqRefundPaymentModal: false,
      payment: {},
      showPlanChangeModal: false,
      toggleComment: false,
      showAddCommentModal: false,
      enrollStatus: '',
      enrollStatusColor: {
        'enrolled': 'primary',
        'completed': 'success',
        'dropped': 'danger',
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.title, active: true },
      ];
    },
    paymentPlanTableColumns() {
      return [
        {
          key: 'date',
          label: 'Due Date',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value),
        },

        { key: 'amount', label: 'Amount' },
        { key: 'status', label: 'Status' },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
    formattedText() {
      return this.registration.comment.replace(/\n/g, '<br>');
    },
  },

  methods: {
    ...mapActions('student', [
      'getStudent',
      'getStudentRegProfile',
      'updateStdRegPayStatus',
      'updateStdRegStatus',
      'getStudentPaymentPlans',
      'updateStdPaymentPlanStatus',
      'getStudentCancelation',
      'getStudentEnrollStatus'
    ]),
    ...mapActions('registrationForm', ['getPublicRegistrationForm']),
    ...mapActions('fileDownload', ['downloadFile']),

    formatFullDate,
    formatDateTime,
    parseFileNameFromUrl,
    formatDateSimple,
    stdPlanToPayOptions,
    get,
    truncate,
    async downloadCancelation(reg) {
      try {
        const h = this.$createElement;
        this.makeToast({
          variant: 'primary',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        });

        const res = await this.getStudentCancelation({ reg_id: reg.id });

        this.makeToast({
          variant: 'primary',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        });

        this.downloadGivenFile({
          fileUrl: res.data.file_url,
          removeTimestamp: true,
        });
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: 'Error occured while exporting file' });
      }
    },
    openAddCommentModal(reg) {
      this.payment = reg;
      this.showAddCommentModal = true;
    },
    hideAddCommentModal() {
      this.showAddCommentModal = false;
    },
    onCommentUpdate(data) {
      this.registration.comment = data.comment;
    },
    openReqRefundPaymentModal(payment) {
      this.payment = payment;
      this.showReqRefundPaymentModal = true;
    },
    hideReqRefundPaymentModal() {
      this.showReqRefundPaymentModal = false;
      this.fetchStudent();
    },
    async updateStatus(plan) {
      try {
        const res = await this.updateStdPaymentPlanStatus({
          reg_id: this.$route.params.id,
          status_paid: plan.status_paid ? false : true,
          plan_id: plan.id,
        });
        this.paymentStatus = res.data.data.payment_status;
        this.fetchPaymentsPlans();
      } catch (error) {
        //
      }
    },
    async fetchPaymentsPlans() {
      this.isLoading = true;

      try {
        const response = await this.getStudentPaymentPlans({
          student_reg: this.$route.params.id,
        });
        this.payments = response.data.payment_plan;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    hideRegStatusModal(no) {
      this.showRegStatusModal = false;
      if (!no) {
        this.fetchStudent();
        this.fetchPaymentsPlans();
      }
    },
    async reactivateRegistration() {
      const data = {
        status: REGISTRATION_TYPES.ACTIVE,

        student_registration: this.registration.id,
      };

      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to reactivate registration for this student?`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (isConfirmed) {
          await this.updateStdRegStatus(data);
          this.fetchStudent();
          this.fetchPaymentsPlans();
        }
      } catch (e) {
        //
      }
    },

    async fetchEnrollStatus() {
      const resp = await this.getStudentEnrollStatus({ std_reg_id: this.$route.params.id });
      this.enrollStatus = resp.data.status;
    },

    async fetchStudent() {
      this.isLoading = true;

      let resp = (await this.getStudentRegProfile({ student_registration: this.$route.params.id })).data;

      // Mapping student data.
      const student = resp.student;

      if (student.birthday) {
        student.birthday = formatDateSimple(student.birthday);
      }

      student.desired_work_type = getHumanReadableCommaSepStr(
        student.desired_work_type.map(type => this.$t(`studentWorkTypeOpts.${type}`)),
        'or'
      );
      student.desired_work_time = getHumanReadableCommaSepStr(
        student.desired_work_time.map(time => this.$t(`studentWorkTimeOpts.${time}`))
      );
      student.desired_work_day = getHumanReadableCommaSepStr(
        student.desired_work_day.map(day => this.$t(`jobWorkDays.${day}`))
      );

      if (get(student, 'lang_preferences.length')) {
        const arr = getLangOptions();
        const langPreference = keyBy(arr, 'value');
        student.lang_preferences = student.lang_preferences.map(lang => langPreference[lang].name);
      }

      if (get(student, 'interested_facility_types.length')) {
        student.interested_facility_types = student.interested_facility_types.map(type => this.FACILITY_TYPES[type]);
      }

      this.student = student;
      this.student.student_ssn = this.student.student_ssn ? '***-**-' + this.student.student_ssn : '';

      this.registration = resp;

      this.paymentStatus = this.registration.payment_status;

      this.isLoading = false;
    },

    async fetchStaticFieldsConfig() {
      this.isDataLoading = true;
      try {
        const resp = (
          await this.getPublicRegistrationForm({
            params: {
              school: this.getLoggedInUser.linked_entity.id,
              enrollment_type: 'enrollment',
              program: this.registration.program.id,
            },
          })
        ).data;

        resp.forEach(obj => {
          this.staticFieldsConfig[obj.field_abbr] = obj.status;
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDataLoading = false;
    },

    async updatePayStatus() {
      this.isPayStatusLoading = true;
      const updatedStatus =
        this.paymentStatus === STD_REG_PAY_STATUSES.COMPLETE_PAID
          ? STD_REG_PAY_STATUSES.UNPAID
          : STD_REG_PAY_STATUSES.COMPLETE_PAID;

      try {
        await this.updateStdRegPayStatus({ id: parseInt(this.$route.params.id), payment_status: updatedStatus });
        this.paymentStatus = updatedStatus;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPayStatusLoading = false;
    },

    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },

    openPlanChangeModal(payment) {
      this.payment = payment;

      this.showPlanChangeModal = true;
    },
    hidePlanChangeModal() {
      this.showPlanChangeModal = false;
      this.fetchStudent();
    },
  },

  async mounted() {
    this.registrationTerms = this.getLoggedInUser.linked_entity.registration_terms;
    this.workTravelOptions = getWorkTravelOptions();
    if (this.$route.params.id) {
      this.fetchEnrollStatus();
      await this.fetchStudent();
      this.fetchStaticFieldsConfig();
      this.fetchPaymentsPlans();
    }
  },
};
</script>
